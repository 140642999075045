let hostname = location.hostname;
hostname = hostname.substring(hostname.indexOf('.'));
if (location.hostname.indexOf('.local') >= 0) {
    appHost = `${location.protocol}//api${hostname}`;
} else if (location.hostname.indexOf('.papv2.sungotech.com') >= 0) {
    appHost = `${location.protocol}//ecasev2-api${hostname}`;
} else {
    appHost = `${location.protocol}//api${hostname}`;
}

module.exports = {
    appHost: appHost,

}