import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from 'vuex-persistedstate'
import { getList, projectDoctor, limitRecord } from "@/api/api";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userInfo: null,
    url: null,
    userId: null,
    basicInformation: null,
    addInformation: null,
    doctor_id: null,
    doctorInfo: null,
    ids: {
      project_id: null,
      sub_id: null,
    },
    idcardList: [], //临时身份证照片
    imgList: [], //临时的证件照片
    reg_type: null, //这个项目是否开启导入医生
    subcase_id: null, //子项目id
    certificationData: null, //资质审核数据
    record_show_date: "", //病例显示的时间
    scheme_name: "", //方案名，判断是实体瘤还是血液瘤
    sub_id: null, //子项目id
    projectInfo: null, //项目数据
    noticeInfo: null, //公告数据
    form_list: [], //@病例上传表单数据
    valid_times: null, //@实名制验证剩余次数
    ifClosed: false, //当前项目是否关闭
    closePop: false, //项目关闭提示
    proj_name: "", //项目名称
    verify: null, //存储是否显示人工申请通道状态(1显示)
    ifFrozen: false, //医生是否冻结
    is_mobile_verify: false,//手机号是否是实名认证
    has_record: false,//当前是否有项目正在打款中
    tabName: null,
    operateLimit: {}, // 病例操作限制
    otherDoctorInfo: {},//注册外籍医生信息
    otherDocRgflag:false,//外籍医生是否注册完成
    utm_source: 'default', // 互联网医院渠道来源
  },
  getters: {
    get_verify_apply(state) {
      return state.verify.is_verify_apply //获取是否显示人工申请通道状态(1显示)
    },
    form_list(state) {
      return state.form_list; //@病例上传表单数据
    },
    headimgurl(state) {
      return state.userInfo.headimgurl;
    },
    sex(state) {
      return state.userInfo.sex;
    },
    isRealname(state) {
      return state.basicInformation.is_realname;
    },
    doctorIdsn(state) {
      return state.basicInformation.idsn;
    },
    mobile(state) {
      return state.basicInformation.mobile;
    },
    sub_id(state) {
      return state.ids.sub_id;
    },
    project_id(state) {
      return state.ids.project_id;
    },
    doctorInfo(state) {
      return state.doctorInfo;
    },
    record_show_date(state) {
      return state.doctorInfo;
    },
    scheme_name(state) {
      return state.scheme_name;
    },
    getSubid(state) {
      return state.sub_id;
    },
    projectInfo(state) {
      return state.projectInfo;
    },
    noticeInfo(state) {
      return state.noticeInfo;
    },
    get_froze_status(state) {
      return state.ifFrozen
    },
    operate_limit(state) {
      return state.operateLimit
    },
    utm_source(state) {
      return state.utm_source
    },
  },
  mutations: {
    setValidApply(state, payload) { //存储是否显示人工申请的状态(1显示)
      state.verify = payload
    },
    setValidTimes(state, payload) {
      state.valid_times = payload;
    },
    setFormList(state, payload) {
      state.form_list = payload;
    },
    setRegType(state, payload) {
      state.reg_type = payload;
    },
    setIdcardList(state, payload) {
      //暂存身份证照片
      state.idcardList = payload;
    },
    clearIdcardList(state) {
      //清空暂存的身份证照片
      state.idcardList = [];
    },
    setImgList(state, payload) {
      state.imgList = payload;
    },
    clearImgList(state) {
      state.imgList = [];
    },
    setDoctorId(state, payload) {
      state.doctor_id = payload;
    },
    setIds(state, payload) {
      state.ids = payload;
    },
    setAddInformation(state, payload) {
      state.addInformation = payload;
    },
    setInformation(state, payload) {
      state.basicInformation = payload;
    },
    setUserId(state, payload) {
      state.userId = payload;
    },
    clearUseId(state) {
      state.userId = null;
    },
    setUserInfo(state, payload) {
      if (state.userInfo === null) {
        state.userInfo = payload.userInfo;
      } else {
        Object.assign(state.userInfo, payload.userInfo);
      }
    },
    setUrl(state, payload) {
      state.url = payload;
    },
    setSubcaseId(state, payload) {
      state.subcase_id = payload;
    },
    setCertificationData(state, payload) {
      state.certificationData = payload;
    },
    clearUserInfo(state) {
      state.userInfo = null;
    },
    setDoctorInfo(state, payload) {
      if (state.doctorInfo === null) {
        state.doctorInfo = payload;
      } else {
        Object.assign(state.doctorInfo, payload);
      }
    },
    setRegisterInfo(state, payload) {
      state.registerInfo = payload;
    },
    clearRegisterInfo(state) {
      state.registerInfo = null;
    },
    setOtherDoctorInfo(state, payload) {
      state.otherDoctorInfo = payload;
    },
    setRecordShowDate(state, payload) {
      state.record_show_date = payload;
    },
    setSchemeName(state, payload) {
      state.scheme_name = payload;
    },
    setSubid(state, payload) {
      state.sub_id = payload;
      limitRecord({
        params: { subproject_id: payload }
      }).then(res => {
        if (res) state.operateLimit = res
      })
    },
    setOperateLimit(state, payload) {
      state.operateLimit = payload;
    },
    setProjectInfo(state, payload) {
      state.projectInfo = payload;
    },
    setNoticeInfo(state, payload) {
      state.noticeInfo = payload;
    },
    setIfclosed(state, payload) {
      state.ifClosed = payload;
    },
    setClosePop(state, payload) {
      state.closePop = payload;
    },
    setIfFrozen(state, payload) { //冻结状态
      state.ifFrozen = payload;
    },
    clearFrozen(state) { //重置冻结状态
      state.ifFrozen = false;
    },
    setProjName(state, payload) { //存项目名
      state.proj_name = payload
    },
    setIsMobileVerify(state, payload) { //手机号是否是实名认证
      state.is_mobile_verify = payload
    },
    setHasRecord(state, payload) {
      state.has_record = payload
    },
    setTabName(state, payload) {
      state.tabName = payload
    },
    setOtherDocRgflag(state, payload) {
      state.otherDocRgflag = payload
    },
    clearOtherDocRgflag(state) { 
      state.otherDocRgflag = false;
    },
    setSource(state, payload) {
      state.utm_source = payload
    },
    resetSource(state) {
      state.utm_source = 'default'
    }
  },
  actions: {
    SET_PROJ_NAME(context, payload) { //存项目名
      context.commit('setProjName', payload)
    },
    SET_FORM_LIST(context, payload) {
      context.commit("setFormList", payload);
    },
    SET_REG_TYPE(context, payload) {
      context.commit("setRegType", payload);
    },
    SET_USER_INFO(context, payload) {
      context.commit("setUserInfo", payload);
    },
    SET_IMG_LIST(context, payload) {
      context.commit("setImgList", payload);
    },
    SET_IDCARD_LIST(context, payload) {
      context.commit("setIdcardList", payload); //存身份证
    },
    SET_DOCTOR_ID(context, payload) {
      context.commit("setDoctorId", payload);
    },
    SET_IDS(context, payload) {
      context.commit("setIds", payload);
    },
    SET_ADD_INFORMATION(context, payload) {
      context.commit("setAddInformation", payload);
    },
    SET_USER_ID(context, payload) {
      context.commit("setUserId", payload);
    },
    SET_INFORMATION(context, payload) {
      context.commit("setInformation", payload);
    },
    SET_SUBCASE_ID(context, payload) {
      context.commit("setSubcaseId", payload);
    },
    SET_CERTIFICATION_DATA(context, payload) {
      context.commit("setCertificationData", payload);
    },
    CLEAR_USER_ID(context) {
      context.commit("clearUseId");
    },
    SET_DOCTOR_INFO(context, payload) {
      context.commit("setDoctorInfo", payload);
    },
    SET_SCHEME_NAME(context, payload) {
      context.commit("setSchemeName", payload);
    },
    GET_IF_FROZEN(context) {
      return new Promise((resolve, reject) => {
        projectDoctor().then(res => {
          console.log("GET_IF_FROZEN冻结:", res)
          if (res.freeze_status == '冻结') {
            console.log("医生已冻结")
            context.commit('setIfFrozen', true)
            return true
          } else {
            console.log("医生未冻结")
            context.commit('setIfFrozen', false)
            return false
          }
        }).catch((err) => {
          reject(err);
        });
      })
    },
    GET_IF_CLOSED(context) {
      //定时关闭项目是否关闭
      return new Promise((resolve, reject) => {
        getList()
          .then((res) => {
            console.log("list", res);
            for (let i of res) {
              if (i.id == localStorage.getItem("project_id")) {
                if (i.stop_date == null) setFalse();
                let temp = i.stop_date;
                temp = temp.substring(0, 19);
                temp = temp.replace(/-/g, "/");
                let closeTime = new Date(temp).getTime();
                let now = new Date().getTime();
                console.log("closeTime", closeTime);
                console.log("now", now);
                //现在是否超过项目关闭的时间 ? 是:否
                now - closeTime >= 0 ? setTrue() : setFalse();
              }
            }
            function setTrue() {
              console.log("项目已关闭");
              context.commit("setIfclosed", true);
              resolve();
            }
            function setFalse() {
              console.log("项目未关闭");
              context.commit("setIfclosed", false);
              resolve();
            }
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  modules: {},
  plugins: [createPersistedState({
    reducer(val) {  //要永久存储的放到这里面
      return {
        // 只储存state中的token，而不会缓存uid
        userInfo: val.userInfo,
        url: val.userInfo,
        userId: val.userId,
        basicInformation: val.basicInformation,
        addInformation: val.addInformation,
        doctor_id: val.doctor_id,
        doctorInfo: val.doctorInfo,
        ids: {
          project_id: val.ids.project_id,
          sub_id: val.ids.sub_id,
        },
        idcardList: val.idcardList, //临时身份证照片
        imgList: val.imgList, //临时的证件照片
        reg_type: val.reg_type, //这个项目是否开启导入医生
        subcase_id: val.subcase_id, //子项目id
        certificationData: val.certificationData, //资质审核数据
        record_show_date: val.record_show_date, //病例显示的时间
        scheme_name: val.scheme_name, //方案名，判断是实体瘤还是血液瘤
        sub_id: val.sub_id, //子项目id
        projectInfo: val.sub_id, //项目数据
        noticeInfo: val.noticeInfo, //公告数据
        form_list: val.form_list, //@病例上传表单数据
        valid_times: val.valid_times, //@实名制验证剩余次数
        ifClosed: val.ifClosed, //当前项目是否关闭
        closePop: val.closePop, //项目关闭提示
        proj_name: val.proj_name, //项目名称
        verify: val.verify, //存储是否显示人工申请通道状态(1显示)
        ifFrozen: val.ifFrozen, //医生是否冻结
        is_mobile_verify: val.is_mobile_verify, //是否实名认证
        has_record: val.has_record, //是否有在支付病例
        operateLimit: val.operateLimit,
        otherDoctorInfo: val.otherDoctorInfo,//外籍医生再次注册信息
        otherDocRgflag: val.otherDocRgflag,//外籍医生再次注册信息
        utm_source: val.utm_source // 互联网医院渠道来源
      }
    }
  })],
});