import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/home",
    name: "Home",
    meta: {
      title: "医学健康数据平台",
    },
    component: () => import("@/views/home/index.vue"),
  },
  {
    path: "/",
    name: "Blank",
    meta: {
      title: "跳转中",
    },
    component: () => import("@/views/blank.vue"),
  },

  {
    path: "/login", //登录
    name: "Login",
    meta: {
      title: "医学健康数据平台",
    },
    component: () => import("@/views/login/login.vue"),
  },

  {
    path: "/login_third", // 第三方登录，如百度互联网医生
    name: "LoginThird",
    meta: {
      title: "医学健康数据平台",
    },
    component: () => import("@/views/login/loginThird.vue"),
  },

  {
    path: "/me", //我的
    name: "Me",
    meta: {
      title: "个人中心",
    },
    component: () => import("@/views/mine/me.vue"),
  },
  {
    path: "/apply", //人工验证申请通道
    name: "Apply",
    meta: {
      title: "人工验证申请通道",
    },
    component: () => import("@/views/mine/apply.vue"),
  },
  {
    path: "/regocr", //实名认证
    name: "regOcr",
    meta: {
      title: "实名认证",
    },
    component: () => import("@/views/mine/reg_ocr.vue"),
  },
  {
    path: "/updateOcr", //实名认证
    name: "UpdateOcr",
    meta: {
      title: "更新实名认证",
    },
    component: () => import("@/views/mine/update_ocr.vue"),
  },
  {
    path: "/ocr", //实名认证
    name: "Ocr",
    meta: {
      title: "实名认证",
    },
    component: () => import("@/views/mine/ocr.vue"),
  },
  {
    path: "/bankcard", //实名认证/银行卡验证
    name: "bankcard",
    meta: {
      title: "实名认证",
    },
    component: () => import("@/views/mine/bankcard.vue"),
  },
  {
    path: "/set", //个人设置
    name: "Set",
    meta: {
      title: "设置",
    },
    component: () => import("@/views/mine/set.vue"),
  },
  {
    path: "/project", //项目中心
    name: "project",
    meta: {
      title: "选择项目",
    },
    component: () => import("@/views/project/center.vue"),
  },
  {
    path: "/sub_project", //子项目
    name: "SubProject",
    meta: {
      title: "选择项目",
    },
    component: () => import("@/views/project/sub_project.vue"),
  },
  {
    path: "/checking", //子项目
    name: "Checking",
    meta: {
      title: "选择项目",
    },
    component: () => import("@/views/project/checking.vue"),
  },
  {
    path: "/add_hospital", //子项目
    name: "AddHospital",
    meta: {
      title: " ",
    },
    component: () => import("@/views/project/add_hospital.vue"),
  },
  {
    path: "/add_idsn", //子项目
    name: "AddIdsn",
    meta: {
      title: " ",
    },
    component: () => import("@/views/project/add_idsn.vue"),
  },
  {
    path: "/add_position", //子项目
    name: "AddPostion",
    meta: {
      title: " ",
    },
    component: () => import("@/views/project/add_position.vue"),
  },
  {
    path: "/add_idcard", //上传身份证正反面图片页面，不做ocr识别
    name: "AddIdcard",
    meta: {
      title: ' '
    },
    component: () =>
      import("@/views/project/add_idcard.vue"),
  },
  {
    path: "/add_sign", //子项目
    name: "AddSign",
    meta: {
      title: "签署协议",
    },
    component: () => import("@/views/project/add_sign.vue"),
  },
  {
    path: "/add_agreement", //子项目
    name: "AddAgreement",
    meta: {
      title: "医生知情同意书",
    },
    component: () => import("@/views/project/add_agreement.vue"),
  },
  {
    path: "/read_agreement", //子项目
    name: "ReadAgreement",
    meta: {
      title: "签署协议",
    },
    component: () => import("@/views/project/read_agreement.vue"),
  },
  {
    path: "/service_agreement", //（诺亚方舟二期签署劳务协议- 协议部分）
    name: "ReadServiceAgreement",
    meta: {
      title: "签署服务协议",
    },
    component: () => import("@/views/project/service_agreement.vue"),
  },
  {
    path: "/sign_service_agreement", //（诺亚方舟二期签署劳务协议- 签字部分）
    name: "SignServiceAgreement",
    meta: {
      title: "签署协议",
    },
    component: () => import("@/views/project/sign_service_agreement.vue"),
  },
  {
    path: "/re_sign_service_agreement", //（重新签署劳务协议- 签字部分）
    name: "ReSignServiceAgreement",
    meta: {
      title: "签署协议",
    },
    component: () => import("@/views/project/re_sign_service_agreement.vue"),
  },
  {
    path: "/read_service_agreement", //（诺亚方舟二期签署劳务协议- 预览协议和签字部分）
    name: "ReadServiceAgreementSign",
    meta: {
      title: "劳务协议",
    },
    component: () => import("@/views/project/read_service_agreement.vue"),
  },
  {
    path: "/re_agreement", //重新签署协议
    name: "ReadReAgreementSign",
    meta: {
      title: "劳务协议",
    },
    component: () => import("@/views/add/re_agreement.vue"),
  },
  {
    path: "/progress", //审核进度
    name: "Progress",
    meta: {
      title: "病例中心",
    },
    component: () => import("@/views/project/progress.vue"),
  },
  {
    path: "/list", //方案列表
    name: "List",
    meta: {
      title: "项目名称",
    },
    component: () => import("@/views/project/list.vue"),
  },
  {
    path: "/caseview", //查看病例
    name: "caseView",
    meta: {
      title: "查看病例",
    },
    component: () => import("@/views/add/case_view.vue"),
  },
  {
    path: "/case", //创建病例
    name: "case",
    meta: {
      title: "病例上传",
    },
    component: () => import("@/views/add/case.vue"),
  },
  {
    path: "/case_agreement", //创建病例
    name: "caseAgreement",
    meta: {
      title: "病例上传",
    },
    component: () => import("@/views/add/agreement.vue"),
  },
  {
    path: "/case_sign", //创建病例
    name: "caseSign",
    meta: {
      title: "病例上传",
    },
    component: () => import("@/views/add/sign.vue"),
  },
  {
    path: "/case_read_agreement", //创建病例
    name: "caseReadAgreement",
    meta: {
      title: "病例上传",
    },
    component: () => import("@/views/add/read_agreement.vue"),
  },
  {
    path: "/video_center", //视频中心
    name: "videoCenter",
    meta: {
      title: "视频中心",
    },
    component: () => import("@/views/video/video_center.vue"),
  },
  {
    path: "/class_center", //云课堂
    name: "classCenter",
    meta: {
      title: "云课堂",
    },
    component: () => import("@/views/class/class_center.vue"),
  },
  {
    path: "/class_library", //课件库
    name: "classLibrary",
    meta: {
      title: "课件库",
    },
    component: () => import("@/views/video/class_library.vue"),
  },
  {
    path: "/choose_template", //选择课件模板
    name: "ChooseTemplate",
    meta: {
      title: "选择课件模板",
    },
    component: () => import("@/views/video/choose_template.vue"),
  },
  {
    path: "/select_case", //选择病例
    name: "SelectCase",
    meta: {
      title: "选择病例",
    },
    component: () => import("@/views/video/select_case.vue"),
  },
  {
    path: "/download_center", //选择病例
    name: "DownloadCenter",
    meta: {
      title: "下载中心",
    },
    component: () => import("@/views/video/download_center.vue"),
  },
  {
    path: "/video_storage", //视频库
    name: "VideoStorage",
    meta: {
      title: "视频库",
    },
    component: () => import("@/views/video/video_storage.vue"),
  },
  {
    path: "/comment_case", //点评病例
    name: "CommentCase",
    meta: {
      title: "点评病例",
    },
    component: () => import("@/views/class/comment_case.vue"),
  },
  {
    path: "/comment_page", //点评病例
    name: "CommentPage",
    meta: {
      title: "视频中心",
    },
    component: () => import("@/views/class/comment_page.vue"),
  },
  {
    path: "/notice/project_list", //公告入口的项目选择列表
    name: "projectList",
    meta: {
      title: "项目选择",
    },
    component: () => import("@/views/notice/project_list.vue"),
  },
  {
    path: "/notice/notice_list", //某个项目的全部公告列表
    name: "noticeList",
    meta: {
      title: "公告列表",
    },
    component: () => import("@/views/notice/notice_list.vue"),
  },
  {
    path: "/notice/notice_info", //公告详情
    name: "noticeInfo",
    meta: {
      title: "公告详情",
    },
    component: () => import("@/views/notice/notice_info.vue"),
  },
  {
    path: "/display/display_list", //成果展示列表
    name: "displayList",
    meta: {
      title: "成果展示",
    },
    component: () => import("@/views/display/displayList.vue"),
  },
  {
    path: "/display/display_detail", //成果展示详情
    name: "displayDetail",
    meta: {
      title: "成果展示",
    },
    component: () => import("@/views/display/displayDetail.vue"),
  },
  {
    path: "/project/informed_consent", //新增知情同意书阅读页面->鳞癌长疗程项目（新征程项目）
    name: "informedConsent",
    meta: {
      title: "",
    },
    component: () => import("@/views/project/informed_consent.vue"),
  },
  {
    path: "/add/pre_filling", //肺肠安好项目预填病例信息页
    name: "preFilling",
    meta: {
      title: "预填病例信息",
    },
    component: () => import("@/views/add/pre_filling.vue"),
  },
  // 灵活用工
  {
    path: "/eac_add_agreement", // 选择领款方式，阅读协议
    name: "EacAddAgreement",
    meta: {
      title: "签署协议",
    },
    component: () => import("@/views/dockEaccount/add_agreement.vue"),
  },
  {
    path: "/eac_add_sign", // 签字
    name: "EacAddSign",
    meta: {
      title: "签署协议",
    },
    component: () => import("@/views/dockEaccount/add_sign.vue"),
  },
  {
    path: "/eac_read_agreement", // 查看协议
    name: "EacReadAgreement",
    meta: {
      title: "签署协议",
    },
    component: () => import("@/views/dockEaccount/read_agreement.vue"),
  },
];

const router = new VueRouter({
  routes,
  mode: 'history'
});

export default router;