const u = navigator.userAgent
// 判断是否微信环境
var   isWeixin = u.toLowerCase().indexOf('micromessenger') != -1
// 判断是否是安卓手机
var androidAttrs = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1
var androidAttrs2 = androidAttrs && u.match(/MMWEBSDK\/200502/i) && u.match(/XWEB\/2353/i)

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueWechatTitle from 'vue-wechat-title'
import axios from "axios";
import Vant from 'vant';
import {
  Dialog
} from "vant";
import wechat from 'jweixin-module';
import vConsole from 'vconsole';
import FastClick from 'fastclick' //解决300ms延迟
import NProgress from 'nprogress' //加载进度条
import 'babel-polyfill'
import Es6Promise from 'es6-promise'
import 'nprogress/nprogress.css'
import 'vant/lib/index.css';
import {
  appHost
} from './utils/host.js'

if (location.hostname.indexOf('.papv2.sungotech.com') >= 0) {
  new vConsole()
}

Vue.use(Vant);
Vue.use(VueWechatTitle)

Es6Promise.polyfill()
FastClick.attach(document.body)

Vue.prototype.$axios = axios
Vue.config.productionTip = false
Vue.prototype.$wechat = wechat
Vue.prototype.isWeixin  = isWeixin
Vue.prototype.androidAttrs = androidAttrs
Vue.prototype.androidAttrs2 = androidAttrs2
axios.defaults.withCredentials = true
axios.defaults.retry = 1
axios.defaults.retryDelay = 500
axios.defaults.timeout = 30000

Vue.prototype.baseUrl = appHost
axios.defaults.baseURL = appHost + '/v2/'

// axios.interceptors.response.use(undefined, function axiosRetryInterceptor(err) {
//   if (err.response && err.response.status === 403) {
//     Dialog.alert({
//       message: '请登录',
//     }).then(() => {
//       router.push({
//         path: '/login'
//       })
//     });
//     return Promise.reject(err);
//   }
//   if (err.response && err.response.status === 401) {
//     store.commit('clearUserInfo')
//     // cookie.remove('_identity-user');
//     window.location.reload()
//   }
//   var config = err.config;
//   if (!config || !config.retry) return Promise.reject(err);

//   config.__retryCount = config.__retryCount || 0;

//   if (config.__retryCount >= config.retry) {

//     // Reject with the error
//     // Vue.$vux.toast.show({
//     //   type: 'cancel',
//     //   // text: '网络超时，请检查网络连接，然后刷新页面',
//     //   text: err.response.data.message,
//     //   time: 5000
//     // })
//     return Promise.reject(err);
//   }

//   config.__retryCount += 1;

//   var backoff = new Promise(function (resolve) {
//     setTimeout(function () {
//       resolve();
//     }, config.retryDelay || 1);
//   });

//   return backoff.then(function () {
//     return axios(config);
//   });
// });
axios.interceptors.response.use(
  (res) => {
    return res.data.data;
  },
  (error) => {
    console.log('axios response interceptor error', error, JSON.stringify(error.response));
    // 对响应错误做点什么
    if (error.response && error.response.status === 403) {
      Dialog.alert({
        message: "请登录",
      }).then(() => {
        router.push({
          path: "/login",
        });
      });
    }
    if (error.response && error.response.status === 401) {
      store.commit("clearUserInfo");
      // cookie.remove('_identity-user');
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

Vue.config.productionTip = false

router.beforeEach(async function (to, from, next) {
  // 互联网医院对接验证
  let outDoctor = ''
  // console.log("互联网医院对接验证", to, outDoctor, to.query.doctor_id, to.query.outside);
  // && (outDoctor != to.query.doctor_id)
  if (to.query && to.query.doctor_id && to.query.outside) {
    store.commit("clearUserInfo");
    store.commit('resetSource')
    // console.log("互联网医院对接验证 s", to);
    let formData = new FormData();
    for (let i in to.query) {
      let ele = to.query[i]
      formData.append(i, ele)
    }
    await axios
    .post('external/check-sign-again', formData)
    .then(res => {
      if (res) {
        outDoctor = to.query.doctor_id
        localStorage.setItem("outDoctor", to.query.doctor_id);
        if (to.query.outside == 1) {
          store.commit("setSource", res)
        }
        if (to.query.outside == 2) {
          if (window.localStorage && to.query.project_id) {
            localStorage.setItem("project_id", to.query.project_id);//把project_id存入localstorage
          }
          store.commit("setSource", res.utm_source)
          store.commit("setUserInfo", {
            userInfo: {
              access_token: res.access_token
            },
          })
          axios.defaults.auth = {
            username: store.state.userInfo.access_token,
          };
          location.href = "/login";
        }
      } else {
        outDoctor = ''
        localStorage.removeItem("outDoctor")
      }
    }).catch(err => {
      outDoctor = ''
      localStorage.removeItem("outDoctor")
    })
    if (!outDoctor) {
      Dialog.alert({
        message: '互联网医院对接失败'
      }).then(() => {
        router.back()
      });
      return
    }
    if (to.query.outside == 2) return
  }
  // console.log('store.state.userInfo',store.state.userInfo)

  const whiteRouteList = ['/home']
  if((location.hostname.indexOf('.papv2.sungotech.com') >= 0 || location.hostname.indexOf('.ecasev2.gz-ilvzhou.com') >= 0) && whiteRouteList.includes(to.fullPath)) {
    document.title = to.meta.title;
    next()
    return
  }
  
  console.log("路由守卫to/from", to, from);
  if (to.meta.title) {
    document.title = to.meta.title;//放入标题
  }
  if (to.path.indexOf("notice") >= 0) {
    console.log("公告!!!!!");
    next(); //如果前往公告,不需要登陆
    return;
  }
  if (to.path.indexOf("display") >= 0) {
    console.log("成果展示!!!!!");
    next(); //如果前往成果展示,不需要登录
    return;
  }

  if (to.path.indexOf("login_third") >= 0 && to.query.utm_source && !isWeixin) {
    console.log("to login!!!!!");
    next(); //第三方（如百度互联网医生）非微信浏览器访问直接跳转登录
    return;
  }

  // 非项目入口，路由query传参需传项目id时，注意尽量不要使用project_id，请使用projectId
  if (window.localStorage && to.query.project_id) {
    localStorage.setItem("project_id", to.query.project_id);//把project_id存入localstorage
    // baidu跳转医生
    if (!to.query.utm_source && !to.query.outside) {
      store.commit('resetSource')
    } else if (['iga_baidu'].includes(to.query.utm_source)) {
      store.commit('setSource', to.query.utm_source)
      if (!isWeixin) {
        // 非微信浏览器访问，跳过微信授权去第三方登录页
        store.commit("clearUserInfo");
        location.href = `/login_third?utm_source=${to.query.utm_source}`
      }
    }

    if (!to.query.outside && store.state.userInfo && store.state.userInfo.doctor && store.state.userInfo.doctor.login_type === 'loginNoPassword') {
      store.commit("clearUserInfo");
      store.commit('resetSource')
      localStorage.removeItem("outDoctor");
    }
  }
  // console.log(store.state.otherDocRgflag, '');
  let code = localStorage.getItem("code") || false;
  if (!store.state.userInfo) {
    if (!code) {
      console.log("第一次访问");
      axios
        .post("oauth/get-redirect-url", {
          redirectUrl:
            window.location.protocol + "//" + location.hostname + to.fullPath,
        })
        .then((res) => {

          window.location.href = res.url;
          localStorage.setItem("code", res.code);
        });
    } else {
      console.log("刷新页面", store.state.utm_source);
      axios
        .post("oauth/get-current-user", {
          code: localStorage.getItem("code"),
          utm_source: store.state.utm_source
        })
        .then((res) => {
          localStorage.removeItem("code");
          if (location.href.indexOf("/login") === -1 && (!res.doctor || res.doctor.login_project_id != localStorage.getItem('project_id'))) {
            location.href = "/login"
          }
          if (location.href.indexOf("/login") === -1 && res.doctor && outDoctor && res.doctor.id != outDoctor) {
            location.href = "/login"
          }
          if (to.query.project_id && !to.query.outside && res.doctor && res.doctor.login_type === 'loginNoPassword') {
            location.href = "/login"
          }
          store.commit("setUserInfo", {
            userInfo: res,
          });
          if (res.doctor) {
            store.commit("setDoctorInfo", {
              doctorInfo: res.doctor,
            });
          }
          axios.defaults.auth = {
            username: store.state.userInfo.access_token,
          };
          if (!res.doctor && location.href.indexOf("/login") === -1) {
            location.href = "/login"
          } else {
            next()
          }
        });
    }
  } else {
    console.log("已经登录", store.state.userInfo);
    await axios
      .post("oauth/get-current-user", {
        access_token: store.state.userInfo.access_token,
        utm_source: store.state.utm_source
      })
      .then((res) => {
        store.commit("setUserInfo", {
          userInfo: res,
        });

        if (location.href.indexOf("/login") === -1 && (!res.doctor || res.doctor.login_project_id != localStorage.getItem('project_id')) && !store.state.otherDocRgflag) {
          location.href = "/login";
        }
        if (res.doctor) {
          store.commit("setDoctorInfo", res.doctor);
        }

        axios.defaults.auth = {
          username: store.state.userInfo.access_token,
        };
        // document.title = to.meta.title
        // 处理jssdk签名,兼容history模式
        if (
          !store.state.url ||
          store.state.url != window.location.href.split("#")[0]
        ) {
          store.commit("setUrl", document.URL);
        }
        if (!res.doctor && location.href.indexOf("/login") === -1 && !store.state.otherDocRgflag) {
          location.href = "/login";
        } else {
          store.commit("clearOtherDocRgflag");
          if (/\/http/.test(to.path)) {
            let url = to.path.split("http")[1];
            window.location.href = `http${url}`;
          } else {
            NProgress.start();
            next();
          }
        }
      });
  }
})

router.afterEach(function (to) {
  // store.commit('updateLoadingStatus', {
  //   isLoading: false
  // })
  NProgress.done();
  if (process.env.NODE_ENV === 'production') {
    // ga && ga('set', 'page', to.fullPath)
    // ga && ga('send', 'pageview')
  }
  Vue.nextTick(async () => {
    var url = ''
    // 判断是否是ios微信浏览器
    if (window.__wxjs_is_wkwebview === true) {
      console.log('__wxjs_is_wkwebview');
      if (store.state.url) {
        url = store.state.url.split('#')[0]
      } else {
        url = window.location.href.split('#')[0]
      }
    } else {
      console.log('is not wkwebview');
      url = window.location.href.split('#')[0]
    }
    console.log('wechat jssdk', url);

    if (isWeixin) {
      await axios.get('wechat/js-sdk-config', {
        params: {
          url: url
        }
      }).then(response => {
        // js-sdk配置
        wechat.config(response);
      })
    }
  }, 500)

})
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
