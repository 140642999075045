import axios from 'axios'
import store from '../store';

// 获取平台用户信息
export function getCurrentUser(data) {
    data.utm_source = store.state.utm_source
    return axios.post(`oauth/get-current-user`, data)
}

//登录信息
export function checkIn(data) {
    return axios.post(`doctor/check-in`, data)
}
//医生注册
export function register(data) {
    data.utm_source = store.state.utm_source
    return axios.post(`doctor/register`, data);
}

// 医生更新身份证
export function updateIdsn(data) {
    data.utm_source = store.state.utm_source
    return axios.post(`doctor/update-idsn`, data)
}

//医生登录
export function login(data) {
    data.utm_source = store.state.utm_source
    return axios.post(`doctor/login`, data)
}

// 第三方医生登录，如百度互联网医生
export function loginByNew(data) {
    data.utm_source = store.state.utm_source
    return axios.post(`wechat/login-by-new`, data)
}


//发送短信
export function sendVerifyCode(data) {
    return axios.post(`default/send-verify-code`, data)
}

//忘记密码
export function modifyPassword(data) {
    return axios.post(`doctor/modify-password`, data)
}

//设置密码
export function setPassword(data) {
    return axios.post(`doctor/set-password`, data)
}

//身份识别
export function idcardOcr(data) {
    data.utm_source = store.state.utm_source
    return axios.post(`doctor/idcard-ocr`, data)
}

//实名认证
export function verifyIdcard(data) {
    data.utm_source = store.state.utm_source
    return axios.post(`doctor/verify-idcard`, data)
}

//实名认证
export function queryBankcard(data) {
    return axios.post(`doctor/query-bankcard`, data)
}

//申请项目
export function apply(data) {
    data.utm_source = store.state.utm_source
    return axios.post(`project-doctor/apply`, data)
}

//医生申请项目第一步
export function StepOne(data) {
    data.utm_source = store.state.utm_source
    return axios.post(`project-doctor/step-one`, data)
}

//医生申请项目第二步
export function StepTwo(data) {
    data.utm_source = store.state.utm_source
    return axios.post(`project-doctor/step-two`, data)
}

//医生申请项目第三步
export function StepThree(data) {
    data.utm_source = store.state.utm_source
    return axios.post(`project-doctor/step-three`, data)
}

// 医生申请项目第四步
export function StepFour(data) {
    data.utm_source = store.state.utm_source
    return axios.post(`project-doctor/step-four`, data)
}


//上传病例
export function submit(data) {
    data.utm_source = store.state.utm_source
    return axios.post(`record/submit`, data)
}

//上传附件信息
export function followSubmit(data) {
    data.utm_source = store.state.utm_source
    return axios.post(`follow-info/submit`, data)
}

//退出
export function loginOut(data) {
    return axios.post(`doctor/login-out`, data)
}

//提交自方案知情同意书
export function Finish(data) {
    data.utm_source = store.state.utm_source
    return axios.post(`record/finish`, data)
}

//删除图片
export function deleteImg(data) {
    data.utm_source = store.state.utm_source
    return axios.post(`record/delete-attach`, data)
}

//删除病例
export function deleteRecord(data) {
    data.utm_source = store.state.utm_source
    return axios.post(`record/delete-record`, data)
}
// 删除随访信息
export function deleteFollow(data) {
    data.utm_source = store.state.utm_source
    return axios.post(`follow-info/delete-follow`, data)
}
//撤销病例
export function cancelRecord(data) {
    data.utm_source = store.state.utm_source
    return axios.post(`record/cancel`, data)
}

// 病例操作限制
// 1 => '创建新病例', 2 => '待上传-编辑', 3 => '待上传-查看', 4 => '待上传-删除',5 => '正在审核-查看', 6 => '审核驳回-编辑',7 => '审核驳回-查看',8 => '审核驳回-删除',9 => '审核通过-查看',10 => '审核通过-开启下一阶段',
export function limitRecord(data) {
    return axios.get(`record/get-operate-limit`, data)
}

//获取医生信息
export function doctor(data) {
    data.params.utm_source = store.state.utm_source
    return axios.get(`doctor/get-info`, data)
}

//获取省份
export function province(data) {
    return axios.get(`province`, data)
}

//获取城市
export function city(data) {
    return axios.get(`city`, data)
}

//获取省份城市
export function provinceCity(data) {
    return axios.get(`default/province-city`, data)
}

//获取医院
export function hospital(data) {
    return axios.get(`hospital`, data)
}

// 获取例例生辉二期项目下的医院
export function projectHospitals(data) {
    return axios.get(`project-doctor/get-project-hospitals`, data)
}

//获取病例列表
export function record() {
    return axios.get(`record`, {
        params: {
            doctor_id: store.state.userId,
            subproject_id: store.getters.sub_id,
            expand: 'doctor,patient,scheme,subscheme,nextRecord,canNextRecord,canCancel,editNextTip,canFollow,existFollowv',
            utm_source: store.state.utm_source
        }
    })
}

// 获取随访列表
export function followInfo() {
    return axios.get(`follow-info`, {
        params: {
            project_doctor_id:store.state.doctor_id,
            expand: 'projectDoctor, doctor,patient,subproject',
            utm_source: store.state.utm_source
        }
    })
}

// 获取病例列表（按姓名/时间升序或降序）
export function recordSort(data) {
    data.params.utm_source = store.state.utm_source
    return axios.get(`record`, data)
}

//获取项目医生信息
export function projectDoctor(data) {
    return axios.get(`project-doctor/get-info`, {
        params: {
            doctor_id: store.state.userId,
            subproject_id: store.getters.sub_id ? store.getters.sub_id : store.state.sub_id,
            utm_source: store.state.utm_source
        }
    })
}

export function resign(data) {
    data.utm_source = store.state.utm_source
    return axios.post(`project-doctor/resign`, data)
}

//获取当前项目和子项目登陆医生的is_mobile_verify
export function projectDoctor_isMobileVerify(data) {
  return axios.get(`project-doctor/get-info`, {
    params: {
      doctor_id: store.state.basicInformation.id,
      subproject_id: store.state.sub_id,
      utm_source: store.state.utm_source
    },
  });
}

//查询医生材料
export function projectDoctorAttach(data) {
    return axios.get(`project-doctor-attach`, data)
}

//删除医生材料
export function deleteAttach(data) {
    return axios.get(`project-doctor-attach/delete-attach`, data)
}

//项目列表
export function getProject(data) {
    data.params.utm_source = store.state.utm_source
    return axios.get(`project/get-list`, data)
}

//子项目列表
export function getSubProject(data) {
    data.params.utm_source = store.state.utm_source
    return axios.get(`subproject/get-list`, data)
}

//子项目列表
export function getSubProjectConfig(data) {
    data.utm_source = store.state.utm_source
    return axios.post(`subproject/get-config`, data)
}

//子项目知情同意书
export function getSubProjectAgreement() {
    console.log('getSubProjectAgreement')
    return axios.get(`subproject`, {
        params: {
            id: store.getters.sub_id ? store.getters.sub_id : store.state.sub_id,
            utm_source: store.state.utm_source
        }
    })
}

//方案列表
export function getScheme(data) {
    return axios.get(`scheme/get-list`, {
        params: {
            subproject_id: store.getters.sub_id,
            project_doctor_id: store.state.doctor_id,
            utm_source: store.state.utm_source
        }
    })
}

//上传图片
export function DownloadImage(data) {
    return axios.get(`wechat/download-image`, data)
}

//上传图片
export function UploadFile(data) {
    return axios.post(`wechat/upload-file`, data)
}

//获取模版信息
export function getForm(data) {
    data.params.utm_source = store.state.utm_source
    return axios.get(`template/get-form`, data)
}

// 获取随访病历的模版信息
export function getFollowForm(data) {
    data.params.utm_source = store.state.utm_source
    return axios.get(`template/get-follow-form`, data)
}

//获取子方案的知情同意书
export function subschemeAgre(data) {
    data.params.utm_source = store.state.utm_source
    return axios.get(`subscheme/get-info`, data)
}

//获取主方案的知情同意书
export function schemeAgre(data) {
    data.params.utm_source = store.state.utm_source
    return axios.get(`scheme/get-info`, data)
}


export function getRecode(data) {
    data.params.utm_source = store.state.utm_source
    return axios.get(`record/get-info`, data)
}

//获取配置项
export function getConfigureTemplate(data) {
    return axios.get(`template/get-configure-template`, data)
}

//获取配置项模版结构
export function getConfigureConfig(data) {
    return axios.get(`template/get-configure-config`, data)
}

//获取医生资质审核表单
export function getDoctorForm(data) {
    data.params.utm_source = store.state.utm_source
    return axios.get(`project-doctor/get-form`, data)
}

// 获取医生的关联方案
export function getLinkScheme(data) {
    data.params.utm_source = store.state.utm_source
    return axios.get(`scheme/get-link-scheme`, data)
}

// 检查是否可以新建病例
export function recordCanSubmit(data) {
    data.utm_source = store.state.utm_source
    return axios.post(`record/can-submit`, data)
}

// 添加银行卡信息
export function addBankcard(data) {
    data.utm_source = store.state.utm_source
    return axios.post(`project-doctor/add-bankcard`, data)
}

// 获取开户行信息
export function addBankList(data) {
    data.utm_source = store.state.utm_source
    return axios.post(`project-doctor/get-bank-list`, data)
}

// 验证手机号
export function verifyMobile(data) {
    data.utm_source = store.state.utm_source
    return axios.post(`doctor/verify-mobile`, data)
}

// 修改手机号
export function changeMobile(data) {
    data.utm_source = store.state.utm_source
    return axios.post(`doctor/change-mobile`, data)
}

// 验证项目
export function verifyProject(data) {
    data.utm_source = store.state.utm_source
    return axios.post(`doctor/verify-project`, data)
}
// 获取医生资质ocr
export function getcertificationOcr(data) {
    data.utm_source = store.state.utm_source
    return axios.post(`project-doctor/certification-ocr`, data)
}

// 上传医生资质信息
export function subCertification(data) {
    data.utm_source = store.state.utm_source
    return axios.post(`project-doctor/step-three`, data)
}

// 病例的文字识别
export function getWordOcr(data) {
    return axios.post(`record/word-ocr`, data)
}

// 文字转语音
export function getwordToAudit(data) {
    return axios.get(`record/word-to-audit`, data)
}

//获取审核通过病例列表
export function recordPass(data) {
    data.params.utm_source = store.state.utm_source
    return axios.get(`record`, data)
}

//检查患者是否能生成病例
export function checkPatient(data) {
    data.utm_source = store.state.utm_source
    return axios.post(`record/check-patient`, data)
}
// 弹框提示
export function addNotice(data) {
    data.utm_source = store.state.utm_source
    return axios.post(`project-doctor/add-notice`, data)
}
//金字塔开启下一阶段检查日期是否间隔一天
export function checkDate(data) {
    return axios.post(`record/check-date`, data)
}

//白紫科研选择用药时间之后是否显示输入治疗原因
export function isShowReason(data) {
    return axios.post(`record/is-show-reason`,data)
}
//项目公告
//获取项目列表
export function getList(data) {
  return axios.get(`notification/get-list`, data);
}
//获取项目公告
export function getProjetNotification(data) {
  return axios.get(`notification/get-projet-notification`, data);
}
//获取公告内容
export function getNotificationInfo(data) {
  return axios.get(`notification/get-notification-info`, data);
}
// 获取第一周期的处方时间
export function getFirstPrescriptionDate(data) {
    data.params.utm_source = store.state.utm_source
    return axios.post(`record/first-prescription-date`,data)
}
// 验证手机号是否已被注册
export function registerFirst(data) {
    data.utm_source = store.state.utm_source
    return axios.post(`doctor/register-first`, data)
}
// 获取科室
export function getDepartment(data) {
    return axios.get(`project-doctor/get-department`, data)
}
// 获取科室明细
export function getCategory(data) {
    return axios.get(`project-doctor/get-category`, data)
}
// 人工申请通道
export function realnameApply(data) {
    data.utm_source = store.state.utm_source
    return axios.post(`realname-apply/apply`, data)
}
// 获取成果展示列表
export function getOutcome(data) {
    return axios.get(`outcome`, data)
}
// 获取成果展示阅读量记录
export function getOutcomeRead(data) {
    return axios.post(`outcome/read`, data)
}
// 切换方案（靶向治疗二期需求）
export function changeScheme(data) {
    data.utm_source = store.state.utm_source
    return axios.post(`record/change-scheme`, data)
}
// 获取成果展示的项目
export function getShowProject(data) {
    return axios.get(`outcome/get-show-project`, data)
}
// （肥肠安好项目）获取上一阶段处方剂量
export function getLastMeasure(data) {
    data.utm_source = store.state.utm_source
    return axios.post(`record/get-last-measure`, data)
}
// 补充阅读知情同意书(鳞癌长疗程（新征程）需求)
export function signInformedConsent(data) {
    return axios.post(`project-doctor/sign-informed-consent`, data)
}
// 获取新病例（鳞癌长疗程项目）
export function getSubschemeId(data) {
    return axios.get(`subscheme`, data)
}
// 肺肠安好项目存储预填信息
export function savePreRecord(data) {
    data.utm_source = store.state.utm_source
    return axios.post(`record/pre-record`, data)
}
// 肺肠安好项目获取预填信息
export function getPreRecord(data) {
    data.utm_source = store.state.utm_source
    return axios.post(`record/get-pre-record`, data)
}
// 肺肠安好项目获取预填信息
export function getPreRecordDetail(data) {
    data.utm_source = store.state.utm_source
    return axios.post(`record/pre-record-detail`, data)
}
//外籍医生=======================start=========================
// 获取注册类型
export function getRegisterType(data) {
  return axios.get(`pre-other-doctor/id-type`, data)
}

//注册外籍医生第一步
export function getRegisterFirst(data) {
    data.utm_source = store.state.utm_source
    return axios.post(`pre-other-doctor/register-first`, data)
}

//注册外籍医生
export function getRegister(data) {
    data.utm_source = store.state.utm_source
    return axios.post(`pre-other-doctor/register`, data)
}

//获取外籍医生信息
export function getOtherdoctorInfo(data) {
    data.utm_source = store.state.utm_source
    return axios.post(`pre-other-doctor/get-info`, data)
}
//外籍医生=======================end===========================

// 互联网医院对接 start
export function checkSignAgain(data) {
    data.utm_source = store.state.utm_source
    // return axios.post(`external/check-sign-again`, data)
    return axios.post(`external/check-sign-again`, data,{"Content-Type":"application/x-www-form-urlencoded; charset=UTF-8"})

}

export function bindDoctor(data) {
    data.utm_source = store.state.utm_source
    return axios.post(`wechat/bind-doctor`, data)
}
// 互联网医院对接 end

// 全部项目
export function allProject(data) {
    return axios.get(`project`, data)
}